@use 'sass:math';
@use './../partials/responsive-breakpoints' as rbp;
@use '@angular/material' as mat;

// @import '@angular/material/theming';

@import './../partials/variables';

@mixin ec-app-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .ec-toolbar,
    .ec-menu-toolbar {
        background-color: mat.get-color-from-palette($primary, lighter);
        border-bottom: $border-width solid mat.get-color-from-palette($accent);
        min-height: 64px;
        display: flex;
        // flex-direction: row;
        justify-content: space-between;

        .ec-toolbar-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .ec-toolbar-actions {
            display: none;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            align-items: center;
        }
        .ec-toolbar-actions-mobile {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }

        @include rbp.for-lg-screens() {
            .ec-toolbar-actions {
                display: flex;
            }

            .ec-toolbar-actions-mobile {
                display: none;
            }
        }

        .ec-toolbar-filter-group {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            align-items: center;
        }

        .ec-text {
            color: mat.get-color-from-palette($accent, darker);
            font-family: $fontFamily;
            font-size: $font-big;
        }

        .ec-toolbar-title {
            color: mat.get-color-from-palette($accent, darker);
            font-family: $fontFamily;
            font-size: $font-title;
            display: none;
        }

        @include rbp.for-lg-screens() {
            .ec-toolbar-title {
                display: block;
            }
        }

        .ec-logo {
            display: flex;
            justify-content: center;

            img {
                width: 100px;
            }
        }

        mat-icon {
            color: mat.get-color-from-palette($accent, darker);
        }

        // button {
        //     color: mat.get-color-from-palette($accent, darker);
        // }

        .mat-mdc-form-field {
            label {
                color: mat.get-color-from-palette($accent, darker);
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-underline {
                background-color: mat.get-color-from-palette($accent, default);
            }
        }
    }

    .ec-action-radio-group {
        display: flex;
        gap: 8px;
    }

    .ec-menu-item-flex {
        display: flex;
        gap: 8px;
    }

    .mat-mdc-snack-bar-label {
        font-size: 20px;
    }

    /** Multi-select autocomplete **/
    .ec-multi-select-wrap {
        .search-field {
            color: mat.get-color-from-palette($accent, default);
            width: 70%;
            padding: 0 $padding * 2;
            margin-bottom: 0px;
        }

        .icon-close {
            top: -2px;
        }

        .select-all {
            padding: 0 $padding * 2 12px;
            color: mat.get-color-from-palette($accent, default);
        }

        .search-field-error {
            color: $ec-error;
        }
    }
    /** END Multi-select autocomplete **/

    /** Confirm Popover **/
    .ec-confirm-dialog-panel-class {
        .mat-mdc-dialog-container {
            padding: 0;
            border-radius: 6px;

            .ec-confirm-popover-wrap {
                background-color: white;
                color: $ec-gray-dark;
                border-radius: 7px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .ec-confirm-popover-header {
                    background-color: mat.get-color-from-palette($primary, lighter);
                    box-sizing: border-box;
                    padding: $padding;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .ec-confirm-popover-icon {
                        color: mat.get-color-from-palette($accent, default);
                        font-size: 48px;
                        height: 48px;
                        width: 48px;
                    }

                    .ec-confirm-popover-title {
                        color: mat.get-color-from-palette($accent, default);
                        font-size: 16px;
                        margin: 0;
                    }
                }
                .ec-confirm-popover-content {
                    padding: $padding * 2 $padding * 3;

                    .ec-confirm-popover-text {
                        font-size: 16px;
                        padding-bottom: $padding;
                    }

                    .ec-confirm-popover-actions {
                        display: flex;
                        gap: 16px;
                    }
                }
            }
        }
    }
    /** END Confirm Popover **/

    /** Keyboard shortcut help */
    .ec-keyboard-shortcut-dialog-panel-class {
        .ec-keyboard-shortcut-dialog-title,
        .ec-keyboard-shortcut-dialog-data {
            margin: 0;
            font-size: 17px;
        }
    }
    /** END Keyboard shortcut help */

    // Notification menu
    .ec-notification-icon-wrap {
        margin-right: $margin * 2;
    }

    .ec-menu-wrap {
        height: 532px;
        max-height: 532px;
        position: relative;
        max-width: 430px;
        min-width: 430px;
        overflow-y: hidden;

        .mat-mdc-menu-content {
            height: calc(100% - 8px);
            padding: 0;

            .ec-notification-header {
                background-color: mat.get-color-from-palette($primary, default);
                padding: $padding $padding * 2;

                .ec-notification-title {
                    font-size: 16px;
                }
            }

            .ec-notification-item {
                cursor: pointer;
                height: calc(100% - 74px);
                overflow-y: auto;
                @include scrollbars(
                    10px,
                    mat.get-color-from-palette($accent, lighter),
                    mat.get-color-from-palette($primary, lighter)
                );

                .ec-notification-wrap {
                    padding: $padding * 2 0 0 0;

                    .ec-notification-text-wrap {
                        padding: 0 $padding * 2;

                        .ec-notification-text,
                        .ec-notification-date,
                        .ec-notification-status {
                            font-size: 14px;
                        }

                        .ec-notification-text {
                            h3 {
                                margin: $margin 0;
                            }
                        }
                    }
                }
            }

            .ec-notification-active {
                background-color: white;
            }

            .ec-notification-inactive {
                background-color: $ec-gray-light;
            }

            .ec-detail-text-wrap {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: center;
                padding-top: $padding;
                text-align: center;
                position: sticky;

                .ec-detail-text {
                    color: mat.get-color-from-palette($accent, darker);
                    cursor: pointer;
                    transition: $transition-fast-open;

                    &:hover {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
    }

    .ec-nav-item-items-wrap {
        position: relative;
        transform: translateX(-50px);

        .ec-nav-item-items,
        .ec-nav-item-move-right {
            align-items: center;
            background-color: $ec-error;
            border-radius: 50%;
            bottom: -20px;
            color: white;
            display: flex;
            font-size: 11px;
            height: 20px;
            justify-content: center;
            left: 5px;
            position: absolute;
            width: 20px;
        }

        .ec-nav-item-move-right {
            bottom: -6px;
            left: 45px;
        }
    }

    .mat-mdc-menu-content {
        padding: 0 !important;

        .ec-menu-user-details {
            background-color: mat.get-color-from-palette($primary, default);
            padding-top: $padding * 2;

            .ec-mud-image-cropper {
                width: 70px;
                height: 70px;
                position: relative;
                overflow: hidden;
                border-radius: 50%;
                margin: 0 auto;

                .ec-mud-image {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                    width: auto;
                }
            }
            .ec-mud-title {
                color: mat.get-color-from-palette($accent, darker);
                font-size: 14px;
                font-weight: bold;
                margin: $margin * 2 $margin * 3;
                text-align: center;
            }
        }
    }

    .ec-menu-toolbar {
        min-height: 40px;
        height: 40px;
    }

    .ec-skeleton-loader {
        height: 800px;
        width: 600px;
        margin: 0 auto;
    }

    .ec-skeleton-loader-small {
        height: 200px;
        width: 150px;
        margin: 0 auto;
    }

    .ec-skeleton-loader-100 {
        height: 100%;
        width: 100%;
    }

    .mat-drawer-container {
        background-color: mat.get-color-from-palette($primary, lighter);
        height: calc(100% - 72px);
        overflow-y: hidden;
        box-sizing: border-box;

        .mat-drawer {
            background-color: mat.get-color-from-palette($primary, lighter);
            border-right: $border-width solid mat.get-color-from-palette($accent);
            min-width: 330px;

            .mat-drawer-inner-container {
                @include scrollbars(
                    10px,
                    mat.get-color-from-palette($accent, lighter),
                    mat.get-color-from-palette($primary, lighter)
                );
            }

            .mat-mdc-list-item {
                color: mat.get-color-from-palette($accent, darker);
                font-size: $font-big;

                .ec-side-icon {
                    padding-right: $padding * 2;
                }
            }
        }

        .mat-drawer-content {
            box-sizing: border-box;
            overflow-y: hidden;

            .ec-container {
                height: 100%;
            }

            .ec-wrap {
                height: 100%;
                box-sizing: border-box;
                @include scrollbars(
                    10px,
                    mat.get-color-from-palette($accent, lighter),
                    mat.get-color-from-palette($primary, lighter)
                );

                .spinner-wrap {
                    height: 100%;
                }

                .mat-mdc-paginator {
                    color: mat.get-color-from-palette($accent, default);
                }

                .ec-main-content-padding {
                    @include scrollbars(
                        10px,
                        mat.get-color-from-palette($accent, lighter),
                        mat.get-color-from-palette($primary, lighter)
                    );
                    overflow-y: auto;
                    padding: 8px;
                }

                .table-wrap,
                .table-wrap-no-pagination,
                .table-wrap-article-search {
                    @include scrollbars(
                        10px,
                        mat.get-color-from-palette($accent, lighter),
                        mat.get-color-from-palette($primary, lighter)
                    );
                    height: calc(100% - 120px);
                    overflow-y: auto;
                    width: 100%;

                    .ec-spinner {
                        position: absolute;
                        bottom: 50%;
                        left: 50%;
                        right: 50%;

                        circle {
                            stroke: mat.get-color-from-palette($accent, default);
                        }
                    }

                    .ec-spinner-bar {
                        position: absolute;
                        top: 57px;
                    }

                    .ec-table {
                        // background: mat.get-color-from-palette($primary, default);
                        width: 100%;

                        .ec-table-image {
                            padding-top: math.div($padding, 2);
                            width: 58px;
                        }

                        .mat-mdc-row {
                            cursor: pointer;

                            .mat-mdc-cell {
                                // max-width: 200px;
                                min-width: 40px;
                            }

                            .ec-truncate-cell {
                                max-width: 60px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .ec-cell-small {
                                max-width: 40px;
                                width: 40px;
                            }

                            .ec-cell-mid {
                                max-width: 80px;
                                width: 80px;
                            }

                            .ec-cell-large {
                                max-width: 120px;
                                width: 120px;
                            }

                            .ec-cell-large-xl {
                                max-width: 180px;
                                width: 180px;
                            }

                            .ec-cell-max {
                                max-width: 230px;
                                width: 230px;
                            }

                            .ec-success-field-th {
                                text-align: center;
                            }

                            .ec-success-field {
                                background-color: $ec-success;
                                text-align: center;
                            }

                            .ec-error-field {
                                background-color: $ec-error;
                                text-align: center;
                            }

                            .ec-active-column {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            // &:hover {
                            //     background: mat.get-color-from-palette($accent, lighter);
                            // }
                        }

                        .mat-mdc-row.highlighted {
                            background: mat.get-color-from-palette($accent, lighter);
                        }

                        tr.ec-detail-row {
                            height: 0;
                        }

                        .ec-element-detail {
                            overflow: hidden;
                            display: flex;

                            .ec-element-text {
                                margin: $margin 0 0 0;

                                .ec-element-text-details-wrap {
                                    display: block;

                                    .ec-element-text-details {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                            }
                        }
                    }
                }
                .table-wrap-article-search {
                    height: calc(100% - 340px);
                }
                .table-wrap-no-pagination {
                    height: calc(100% - 64px);
                }

                .ec-form-wrap {
                    height: calc(100% - 64px);
                    // height: 100%;
                    width: 100%;
                }

                .ec-form-scroll,
                .ec-form-scroll-reduce-height,
                .ec-form-scroll-tab-group,
                .ec-form-scroll-list {
                    height: 100%;
                    overflow-y: auto;
                    width: 100%;
                    @include scrollbars(
                        10px,
                        mat.get-color-from-palette($accent, lighter),
                        mat.get-color-from-palette($primary, lighter)
                    );

                    .no-pointer {
                        cursor: default;
                    }
                }

                .ec-form-scroll-reduce-height {
                    height: calc(100% - 64px);
                }

                .ec-form-scroll-tab-group {
                    height: calc(100% - 135px);
                }

                .ec-form-scroll-list {
                    height: calc(100% - 120px);
                }

                .ec-form-with-image {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .ec-image-wrap {
                        cursor: pointer;
                        font-size: $font-big;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        margin: 0 auto;

                        .ec-image {
                            // height: (227px * 4) / 3;
                            width: 100%;
                            height: 100%;
                            margin: 0 auto;
                        }

                        .ec-image-horizontal {
                            height: 400px;
                            width: math.div((400px * 4), 3);
                            margin: 0 auto;
                        }
                    }

                    .ec-data-wrap {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        width: 100%;

                        > .mat-mdc-card {
                            width: calc(50% - 16px);
                            flex-grow: 1;
                        }

                        .mat-mdc-form-field {
                            width: 100%;
                        }

                        .ec-full-name-error-wrap {
                            position: relative;

                            .ec-same-name-error {
                                color: #ffab91;
                                font-size: 11px;
                                position: absolute;
                                text-align: center;
                                top: 36px;
                            }
                        }
                    }
                }

                @include rbp.for-lg-screens() {
                    .ec-form-with-image {
                        flex-direction: row;
                        gap: 16px;

                        .ec-image-wrap {
                            width: 50%;
                        }

                        .ec-data-wrap {
                            flex-direction: column;
                            width: calc(50% - 16px);

                            > .mat-mdc-card {
                                width: auto;
                            }
                        }
                    }
                }

                .mat-mdc-card-actions,
                .mat-mdc-card-subtitle {
                    button {
                        background-color: mat.get-color-from-palette($accent, default);
                        color: mat.get-color-from-palette($primary, default);
                    }
                    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
                    .mat-card-actions-row {
                        width: 100%;
                    }
                }

                .ec-article-charge-wrap {
                    display: flex;
                    flex-direction: row;
                    height: 100%;

                    .ec-article-charge-data-wrap {
                        width: 25%;
                        overflow-y: auto;
                        @include scrollbars(
                            10px,
                            mat.get-color-from-palette($accent, lighter),
                            mat.get-color-from-palette($primary, lighter)
                        );

                        .ec-article-charge-data {
                            display: flex;
                            gap: 8px;
                            flex-direction: column;

                            .ec-article-charge-select-user {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .mat-mdc-form-field {
                                    width: 100%;
                                }
                            }

                            .ec-article-charge-user-data {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .ec-image-wrap {
                                    overflow: hidden;
                                    width: 50%;

                                    .ec-image {
                                        height: 150px;
                                    }
                                }

                                .ec-user-data {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;
                                    // width: 50%;
                                }
                            }
                        }

                        .ec-charged-temp-article-wrap {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 16px;

                            .ec-charged-temp-article {
                                display: flex;
                                flex-direction: row;
                                padding-bottom: $basePXvalue;

                                .title {
                                    background-color: $ec-gray-light;
                                    color: $ec-gray-dark;
                                    // width: 100%;
                                    padding: 4px;
                                }
                                .quantity {
                                    background-color: mat.get-color-from-palette($accent, lighter);
                                    color: white;
                                    padding: 4px;
                                }
                            }
                        }

                        .ec-charge-return-list-wrap {
                            display: flex;
                            flex-direction: column;
                            gap: $basePXvalue;

                            .ec-charge-return-list-item {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-items: center;
                                justify-content: space-between;

                                .ec-crli-text {
                                    width: 90%;
                                }

                                .ec-crli-button {
                                    width: 10%;
                                }
                            }
                        }
                    }

                    .ec-article-charge-article-wrap {
                        display: flex;
                        flex-grow: 1;
                        width: 75%;

                        .mat-mdc-tab-group {
                            width: 100%;
                            height: 100%;

                            .mat-mdc-tab-body {
                                // overflow-y: auto;
                                display: flex;
                                flex-direction: column;
                                gap: 16px;

                                .mat-mdc-tab-body-content {
                                    @include scrollbars(
                                        10px,
                                        mat.get-color-from-palette($accent, lighter),
                                        mat.get-color-from-palette($primary, lighter)
                                    );
                                    height: 100%;
                                    overflow: auto;
                                }

                                .ec-flex-row-grid {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    gap: 8px;

                                    .ec-article-charge-article {
                                        width: calc(30% - 8px);
                                    }

                                    .ec-article-temp-card {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: calc(25% - 8px);

                                        .text-wrap {
                                            width: 100%;
                                            text-align: center;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;

                                            .text {
                                                font-size: 18px;
                                                line-height: 25px;
                                                margin: 0;
                                                text-wrap: pretty;
                                            }
                                        }

                                        .ec-article-temp-card-input {
                                            width: 90%;
                                        }
                                    }
                                }

                                .ec-article-charge-buttons {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 16px;
                                    width: 90%;
                                    align-items: stretch;
                                    min-height: 150px;
                                    margin: 0 auto;
                                    padding: $basePXvalue;

                                    .ec-article-charge-button {
                                        box-sizing: border-box;
                                        border: math.div($basePXvalue, 2) solid transparent;
                                        color: $ec-gray-dark;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: center;
                                        flex-grow: 1;
                                        align-items: center;
                                        font-size: 28px;
                                    }
                                    .return-btn {
                                        color: $ec-error;
                                    }
                                    .charge-btn {
                                        color: $ec-info;
                                    }
                                    .finish-btn {
                                        color: black;
                                    }
                                    .btn-in-use {
                                        border: math.div($basePXvalue, 2) solid $ec-gray-dark;
                                        background-image: linear-gradient(
                                            45deg,
                                            #ffffff 25%,
                                            #d5d2d2 25%,
                                            #d5d2d2 50%,
                                            #ffffff 50%,
                                            #ffffff 75%,
                                            #d5d2d2 75%,
                                            #d5d2d2 100%
                                        );
                                        background-size: 8.49px 8.49px;
                                    }
                                }
                            }
                        }

                        .ec-charged-article,
                        .ec-returned-article,
                        .ec-sent-to-wash-article,
                        .ec-ready-to-charge-article {
                            border-bottom: math.div($basePXvalue, 2) solid var(--ec-error);
                            color: mat.get-color-from-palette($accent, darker);
                            padding: $padding;
                            text-align: center;
                        }
                        .ec-returned-article {
                            border-bottom: math.div($basePXvalue, 2) solid var(--ec-success);
                        }
                        .ec-sent-to-wash-article {
                            border-bottom: math.div($basePXvalue, 2) solid var(--ec-warn);
                        }
                        .ec-ready-to-charge-article {
                            border-bottom: math.div($basePXvalue, 2) solid var(--ec-info);
                        }
                    }

                    .ec-article-charge-article-temp-wrap {
                        display: flex;
                        flex-grow: 1;
                        width: 75%;
                    }
                }

                @include rbp.for-xl-screens() {
                    .ec-article-charge-wrap {
                        .ec-article-charge-data-wrap {
                            .ec-article-charge-data {
                                .ec-article-charge-select-user {
                                    flex-direction: row;
                                    gap: 8px;

                                    .mat-mdc-form-field {
                                        width: calc(50% - 8px);
                                    }
                                }
                                .ec-article-charge-user-data {
                                    flex-direction: row;
                                    gap: 8px;

                                    .ec-image-wrap {
                                        overflow: hidden;
                                        // min-width: 100px;
                                        width: 50%;

                                        .ec-image {
                                            height: 260px;
                                        }
                                    }

                                    .ec-user-data {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 8px;
                                        width: 50%;
                                    }
                                }
                            }
                        }

                        .ec-article-charge-article-wrap {
                            .mat-mdc-tab-group {
                                .mat-mdc-tab-body {
                                    .ec-flex-row-grid {
                                        .ec-article-charge-article {
                                            width: calc(20% - 8px);
                                        }
                                        .ec-article-temp-card {
                                            width: calc(20% - 8px);
                                        }
                                    }
                                }
                            }
                            .ec-article-charge-button {
                                font-size: 24px;
                            }
                        }
                    }
                }

                .ec-flex-grid {
                    box-sizing: border-box;
                    display: flex;
                    gap: 16px;
                    width: 100%;

                    .ec-flex-row {
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                        flex-grow: 1;
                    }

                    .ec-flex-row-grid {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    .ec-flex-column {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                    }

                    .ec-flex-column-gap-center {
                        display: flex;
                        gap: 16px;
                        align-items: center;
                    }

                    .ec-flex-gap-16 {
                        gap: 16px;
                    }

                    .ec-flex-grow-0 {
                        flex-grow: 0 !important;
                    }

                    .ec-flex-align-start {
                        align-items: flex-start;
                    }

                    .ec-flex-row-small-col {
                        display: flex;
                        flex-direction: column;
                        gap: 0;

                        .mat-mdc-form-field {
                            width: 100%;
                        }
                    }

                    @include rbp.for-lg-screens() {
                        .ec-flex-row-small-col {
                            flex-direction: row;
                            gap: 8px;

                            .mat-mdc-form-field {
                                width: 50%;
                            }
                        }
                    }

                    .ec-flex-grid-20 {
                        width: calc(20% - 16px) !important;
                        flex-grow: 1;
                    }
                    .ec-flex-grid-80 {
                        width: 80% !important;
                        flex-grow: 1;
                    }

                    .ec-flex-grid-30 {
                        width: calc(30% - 16px) !important;
                        flex-grow: 1;
                    }
                    .ec-flex-grid-70 {
                        width: 70% !important;
                        flex-grow: 1;
                    }

                    .ec-flex-grid-40 {
                        width: calc(40% - 16px) !important;
                        flex-grow: 1;
                    }
                    .ec-flex-grid-60 {
                        width: 60% !important;
                        flex-grow: 1;
                    }

                    .ec-flex-grid-50 {
                        width: 50% !important;
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    .mat-mdc-tab-body-wrapper {
        height: 100%;
    }

    .ec-flex-row {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        > .ec-input-field {
            flex-grow: 1;
        }

        > .mat-mdc-form-field {
            flex-grow: 1;
        }
    }

    .ec-flex-80 {
        width: 80%;
    }

    .ec-flex-20 {
        width: 20%;
    }

    .ec-flex-50 {
        width: calc(50% - 8px);
    }

    .ec-flex-grow {
        flex-grow: 1;
    }

    .ec-flex-wrap {
        flex-wrap: wrap;
    }

    .ec-flex-column-gap {
        gap: 16px;
    }

    .ec-flex-column {
        display: flex;
        flex-direction: column;
    }

    .ec-flex-column-gap-center {
        gap: 16px;
        align-items: center;
    }

    .ec-flex-row-small-col {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    @include rbp.for-lg-screens() {
        .ec-flex-row-small-col {
            flex-direction: row;
            gap: 8px;
        }
    }

    .ec-mobile-show {
        display: flex !important;
    }

    .ec-mobile-hide {
        display: none !important;
    }

    @include rbp.for-lg-screens() {
        .ec-mobile-show {
            display: none !important;
        }

        .ec-mobile-hide {
            display: flex !important;
        }
    }

    .ec-padding-all-8 {
        padding: 8px;
    }
    .ec-padding-top-16 {
        padding-top: 16px;
    }

    .ec-tab-group {
        height: 100%;
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-body-wrapper {
            height: 100%;
        }
    }

    .ec-margin-bottom-24 {
        margin-bottom: 24px !important;
    }

    .ec-form-textarea-wrap {
        width: 100%;

        .ec-form-textarea {
            @include scrollbars(
                10px,
                mat.get-color-from-palette($accent, lighter),
                mat.get-color-from-palette($primary, lighter)
            );
            caret-color: mat.get-color-from-palette($accent, darker);
            height: 72px;
            resize: none;
        }
    }

    .ec-number-of-editing-items {
        color: var(--ec-error);
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    // button {
    //     background-color: mat.get-color-from-palette($accent, default);
    //     color: mat.get-color-from-palette($primary, default);
    // }

    .ec-footer {
        background-color: mat.get-color-from-palette($primary, lighter);
        border: 0;
        border-top: $border-width solid mat.get-color-from-palette($accent);
        max-height: 32px;
        min-height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ec-text {
            font-size: $font-normal;
        }

        .ec-logo {
            display: flex;
            justify-content: center;

            img {
                width: 80px;
            }
        }
    }

    // .mat-mdc-option-active {
    //     background-color: mat.get-color-from-palette($primary, darker) !important;
    // }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
        .mdc-list-item__primary-text {
        color: black !important;
    }

    .mat-mdc-selected {
        color: mat.get-color-from-palette($accent, default) !important;
    }

    .mat-mdc-input-element {
        caret-color: mat.get-color-from-palette($accent, darker);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
    .mat-card-content,
    .mat-autocomplete-panel,
    .mat-dialog-container,
    .mat-dialog-content,
    .mat-select-panel,
    .mat-menu-panel {
        @include scrollbars(
            10px,
            mat.get-color-from-palette($accent, lighter),
            mat.get-color-from-palette($primary, lighter)
        );
    }

    .mat-mdc-dialog-container {
        overflow: hidden;
    }

    .ec-icon-add-more {
        color: mat.get-color-from-palette($accent, default);
        font-size: 40px;
        height: 40px;
        width: 40px;
    }

    .ec-icon-float {
        position: absolute;
        top: $basePXvalue;
        left: $basePXvalue;
        z-index: 99;
    }

    .ec-cursor-pointer {
        cursor: pointer;
    }

    .ec-cursor-default {
        cursor: default;
    }

    .ec-icon,
    .ec-icon-edit,
    .ec-icon-close,
    .ec-icon-add-more,
    .ec-icon-filter,
    .ec-icon-menu {
        cursor: pointer;
    }
    .ec-user-comments-title {
        margin-top: 0;
    }

    .ec-inventory-charge-item {
        padding: 0;

        .ec-inventory-charge-header,
        .ec-inventory-warehouse-header,
        .ec-inventory-service-header,
        .ec-inventory-shelve-header {
            background-color: mat.get-color-from-palette($accent, darker);
            // color: black;
            padding: $padding * 2 $padding 0;
            text-align: center;
            width: 100%;
        }

        .ec-inventory-item-image {
            height: 215px;
        }

        .ec-inventory-warehouse-header {
            background-color: mat.get-color-from-palette($accent, default);
        }

        .ec-inventory-service-header {
            background-color: $ec-warn;
        }

        .ec-inventory-shelve-header {
            background-color: $ec-error;
        }

        .ec-inventory-charge-item-image,
        .ec-inventory-charge-item-actions {
            padding: $padding * 2;
        }

        .ec-inventory-charge-item-image {
            img {
                width: 100%;
            }
        }
    }

    .ec-form-autocomplete-wrap {
        width: 100%;
    }

    /** Title underline **/
    .ec-section-title {
        overflow: hidden;
        position: relative;

        &:before {
            content: '';
            background-color: mat.get-color-from-palette($accent, default);
            bottom: 0;
            height: 3px;
            left: -15px;
            margin-left: 1rem;
            position: absolute;
            width: 55px;
        }
    }
    /** END Title underline **/

    .ec-margin-bottom-small {
        margin-bottom: $basePXvalue;
    }

    .ec-detail {
        color: mat.get-color-from-palette($accent, default);
    }

    .ec-icon-edit {
        color: mat.get-color-from-palette($primary, darker);
    }

    .ec-icon-close {
        color: mat.get-color-from-palette($warn, default);
    }

    .ec-history-success-row {
        background-color: mat.get-color-from-palette($accent, lighter);
    }

    .ec-disable-button {
        background-color: mat.get-color-from-palette($primary, darker) !important;
    }

    .ec-text-align-center {
        text-align: center;
    }

    .ec-vertical-scroll {
        @include scrollbars(
            10px,
            mat.get-color-from-palette($accent, lighter),
            mat.get-color-from-palette($primary, lighter)
        );
        overflow-y: auto;
    }

    // ARTICLE COLORS
    .ec-article-white,
    .ec-article-black,
    .ec-article-blue,
    .ec-article-blue-dark,
    .ec-article-beige,
    .ec-article-yellow,
    .ec-article-green,
    .ec-article-gray,
    .ec-article-gray-dark,
    .ec-article-red {
        background-color: white !important;
        border: 1px solid black;
    }
    .ec-article-black {
        background-color: black !important;

        span {
            color: white !important;
        }
    }
    .ec-article-blue {
        background-color: var(--ec-blue-light) !important;
    }
    .ec-article-blue-dark {
        background-color: var(--ec-blue-dark) !important;

        span {
            color: white !important;
        }
    }
    .ec-article-beige {
        background-color: var(--ec-beige) !important;
    }
    .ec-article-yellow {
        background-color: var(--ec-yellow) !important;
    }
    .ec-article-green {
        background-color: var(--ec-green) !important;
    }
    .ec-article-gray {
        background-color: var(--ec-gray-light) !important;
    }
    .ec-article-gray-dark {
        background-color: var(--ec-gray-dark) !important;

        span {
            color: white !important;
        }
    }
    .ec-article-red {
        background-color: var(--ec-red) !important;

        span {
            color: white !important;
        }
    }

    .ec-charged-article {
        background-color: var(--ec-error) !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
        border: 1px solid black;
    }

    .ec-returned-article {
        background-color: var(--ec-warn) !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
        border: 1px solid black;
    }

    .ec-wash-article {
        background-color: var(--ec-info) !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
        border: 1px solid black;
    }

    // ERROR / SUCCESS
    .ec-notification-box {
        .sn-title {
            font-size: 15px;
            line-height: 25px;
        }
    }

    .ec-active-field {
        background-color: $ec-success;
        // color: mat.get-color-from-palette($primary, darker);
        // padding: 8px;
        text-align: center;
    }

    .ec-inactive-field {
        background-color: $ec-success;
        // color: mat.get-color-from-palette($primary, darker);
        // padding: 8px;
        text-align: center;
    }

    .ec-success-field {
        background-color: $ec-success !important;
        color: mat.get-color-from-palette($accent, darker);
        // padding: $padding;
        text-align: center;
        border: 1px solid black;
    }

    .ec-success {
        background-color: $ec-success !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
    }

    .ec-warn-field {
        background-color: $ec-warn !important;
        color: mat.get-color-from-palette($accent, darker);
        // padding: $padding;
        text-align: center;
        border: 1px solid black;
    }

    .ec-info-field {
        background-color: var(--ec-info) !important;
        color: mat.get-color-from-palette($accent, darker);
        // padding: $padding;
        text-align: center;
        border: 1px solid black;
    }

    .ec-warn {
        background-color: $ec-warn !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
    }

    .ec-light-gray-background {
        background-color: var(--ec-gray-light);
    }

    .ec-item-box-wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .ec-item-box,
        .ec-item-box-alt {
            background-color: $ec-success !important;
        }

        .ec-item-box-title {
            font-weight: bold;
        }

        .ec-item-box-alt {
            background-color: $ec-error !important;
            color: mat.get-color-from-palette($accent, darker);
        }
    }

    .ec-error-field {
        background-color: $ec-error !important;
        color: mat.get-color-from-palette($accent, darker);
        // padding: $padding;
        // text-align: center;
        border: 1px solid black;
    }

    .ec-error {
        background-color: $ec-error !important;
        color: mat.get-color-from-palette($accent, darker);
        text-align: center;
    }

    .default-notification-overlay {
        background-color: $ec-default;
        text-align: center;
    }

    .info-notification-overlay {
        background-color: $ec-info;
        text-align: center;
    }

    .success-notification-overlay {
        background-color: $ec-success;
    }

    .warn-notification-overlay {
        background-color: $ec-warn;
    }

    .error-notification-overlay {
        --mdc-snackbar-container-color: var(--ec-red) !important;
    }
}
