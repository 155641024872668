/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/purple-green.css';
@import './partials/variables';

// @import '@angular/material/theming';

@include mat.core();

// @import './themes/default-theme';
@import './themes/light-theme';
// @import './themes/black-theme';
// @import './themes/nature-theme';

@import './app/app.component.scss-theme.scss';

@mixin custom-components-theme($theme) {
    @include ec-app-component-theme($theme);
}

// .default-theme {
//     @include angular-material-theme($ec-default-theme);
//     @include custom-components-theme($ec-default-theme);
// }

.light-theme {
    @include mat.all-component-themes($ec-light-theme);
    // @include mat.all-component-colors($ec-light-theme);
    @include custom-components-theme($ec-light-theme);
}

// .black-theme {
//     @include angular-material-theme($ec-black-theme);
//     @include custom-components-theme($ec-black-theme);
// }

// .nature-theme {
//     @include angular-material-theme($ec-nature-theme);
//     @include custom-components-theme($ec-nature-theme);
// }

html,
body {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: $font-large;
    margin: 0;
    overflow: hidden;

    .theme-wrapper {
        height: 100%;
    }

    .mat-toolbar,
    .mat-mdc-button {
        font-size: $font-large;
    }

    .mat-mdc-paginator {
        width: 100%;
    }

    .mat-pseudo-checkbox-checked {
        background: var(--ec-beige) !important;
    }
}
