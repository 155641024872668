@use '@angular/material' as mat;

:root {
    --ec-blue-light: #c9ebf9;
    --ec-blue-dark: #0455bf;
    --ec-beige: #d7a480;
    --ec-yellow: #f2d649;
    --ec-green: #00bf8a;
    --ec-gray-light: #eeeeee;
    --ec-gray-normal: #757575;
    --ec-gray-dark: #737373;
    --ec-red: #e23736;

    --ec-default: #a1887f;
    --ec-info: #64b5f6;
    --ec-success: #9ccc65;
    --ec-warn: #ffca28;
    --ec-error: #ff7043;
}

// $primary: #424242;
// $primary-light: #6d6d6d;
// $primary-dark: #1b1b1b;

// $sec: #aeea00;
// $sec-light: #e4ff54;
// $sec-dark: #79b700;

// $font-dark: #000000;
// $font-light: #ffffff;

// $ec-red: #c62828;
$ec-gray-light: #eeeeee;
$ec-gray-normal: #757575;
$ec-gray-dark: #494949;
$ec-default: #a1887f;
$ec-info: #64b5f6;
$ec-success: #9ccc65;
$ec-warn: #ffca28;
$ec-error: #ff7043;

$font-small: 8px;
$font-normal: 10px;
$font-large: 12px;
$font-big: 14px;
$font-title: 22px;

$font-icon: 18px;

$fontFamily: Roboto, 'Helvetica Neue', sans-serif;

$toolbar-height: 64px;
$border-width: 1px;

$margin: 8px;
$padding: 8px;
$basePXvalue: 8px;

// TRANSITIONS
$icon-animation-simple: 100ms;
$icon-animation-average: 200ms;
$icon-animation-complex: 500ms;

$transition-fast-open: 250ms;
$transition-fast-close: 200ms;
$transition-average-open: 550ms;
$transition-average-close: 500ms;
$transition-slow-open: 850ms;
$transition-slow-close: 800ms;
// END

@mixin scrollbars($size, $foreground-color, $background-color: $foreground-color) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}
