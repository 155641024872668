@mixin for-sm-screens() {
    @media (max-width: 830px) {
        @content;
    }
}

@mixin for-lg-screens() {
    @media (min-width: 1052px) {
        @content;
    }
}

@mixin for-xl-screens() {
    @media (min-width: 1292px) {
        @content;
    }
}
